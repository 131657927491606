import { createSlice } from "@reduxjs/toolkit";
import { countData } from "../page/cho/util";
var initialState = {
  gear: "투구",
  stage: 1,
  blessing: 0,
  // 엘조윈 가호
  tradeCount: 2,
  //남은 정령 교체 횟수
  currentSummonCount: 0,
  //현재 소환 횟수
  leftSummonCount: 7,
  //남은 소환 횟수
  nextCard: ["업화", "업화", "업화"],
  cards: [{
    name: "낙뢰",
    tier: 1
  },
  //정령명, 등급
  {
    name: "용오름",
    tier: 1
  } //정령명, 등급
  ],
  board: [[-1, 1, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 1, -1]],
  guidBoard: [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]],
  select: -1,
  specialTile: [-1, -1]
};
var choSlice = createSlice({
  name: "cho",
  initialState: initialState,
  reducers: {
    setGear: function setGear(state, action) {
      var gear = action.payload.gear;
      state.gear = gear;
      state.tradeCount = 2;
      state.blessing = 0;
      state.leftSummonCount = countData[state.gear][state.stage];
      state.currentSummonCount = 0;
    },
    setStage: function setStage(state, action) {
      var stage = action.payload.stage;
      state.stage = stage;
      //진행 값 초기화
      state.tradeCount = 2;
      state.blessing = 0;
      state.leftSummonCount = countData[state.gear][state.stage];
      state.currentSummonCount = 0;
    },
    resetState: function resetState(state) {
      //진행 값 초기화
      state.tradeCount = 2;
      state.blessing = 0;
      state.leftSummonCount = countData[state.gear][state.stage];
      state.currentSummonCount = 0;
    },
    setBlessing: function setBlessing(state, action) {
      var bless = action.payload.bless;
      state.tradeCount -= state.blessing;
      state.tradeCount += Number(bless);
      state.blessing = bless;
    },
    trade: function trade(state) {
      if (state.tradeCount > 0) {
        state.tradeCount -= 1;
      }
    },
    addTrade: function addTrade(state) {
      state.tradeCount += 1;
    },
    summon: function summon(state) {
      state.leftSummonCount -= 1;
      state.currentSummonCount += 1;
    },
    move: function move(state, action) {
      //최대 두번 연속 이동한다.
      var idx = action.payload.idx;
      var changeIdx = idx;
      while (true) {
        //추출
        var next = state.nextCard.pop();
        //새로운 카드 추가
        state.nextCard.unshift("낙뢰");

        //교체
        state.cards[changeIdx] = {
          name: next,
          tier: 1
        };

        //둘의 이름이 다르면 종료
        if (state.cards[0].name !== state.cards[1].name) break;

        //둘중 한쪽이 tier가 3이면 종료
        if (state.cards[0].tier === 3 || state.cards[1].tier === 3) break;

        //카드가 동일해서 이제 합쳐야 된다.
        if (state.cards[0].tier >= state.cards[1].tier) {
          //왼쪽카드 등급 상승
          changeIdx = 1; //오른쪽카드 교체 다시 진행
          state.cards[0].tier += 1;
        } else {
          //오른쪽카드 드급상승
          changeIdx = 0; //왼쪽카드 교체 다시 진행
          state.cards[1].tier += 1;
        }
      }
    },
    setBoard: function setBoard(state, action) {
      var board = action.payload.board;
      state.board = board;
    },
    setTile: function setTile(state, action) {
      var _action$payload = action.payload,
        row = _action$payload.row,
        col = _action$payload.col,
        status = _action$payload.status;
      if (status > 2) {
        //특수타일지정
        //이전 특수타일이 여전히 특수타일이면 제거
        if (state.specialTile[0] !== -1 && state.board[state.specialTile[0]][state.specialTile[1]] > 2) {
          state.board[state.specialTile[0]][state.specialTile[1]] = 1;
        }

        //특수타일 저장
        state.specialTile = [row, col];
      } else {
        //만약 특수타일을 지울경우
        if (state.specialTile[0] !== -1 && row === state.specialTile[0] && col === state.specialTile[1]) {
          //특수타일 위치 제거
          state.specialTile = [-1, -1];
        }
      }
      state.board[row][col] = status;
    },
    setNextCard: function setNextCard(state, action) {
      var _action$payload2 = action.payload,
        idx = _action$payload2.idx,
        card = _action$payload2.card;
      state.nextCard[idx] = card;
    },
    setCard: function setCard(state, action) {
      var _action$payload3 = action.payload,
        idx = _action$payload3.idx,
        card = _action$payload3.card;
      state.cards[idx] = {
        name: card,
        tier: 1
      };
    },
    setCardTier: function setCardTier(state, action) {
      var _action$payload4 = action.payload,
        idx = _action$payload4.idx,
        num = _action$payload4.num;
      state.cards[idx]["tier"] += num;
      if (state.cards[idx]["tier"] > 3) {
        state.cards[idx]["tier"] = 3;
      }
      if (state.cards[idx]["tier"] < 1) {
        state.cards[idx]["tier"] = 1;
      }
    },
    setSelect: function setSelect(state, action) {
      var idx = action.payload.idx;
      state.select = idx;
    },
    setGuidBoard: function setGuidBoard(state, action) {
      var _action$payload5 = action.payload,
        row = _action$payload5.row,
        col = _action$payload5.col,
        status = _action$payload5.status;
      //외부 나갔을때, 현재 빈곳은 제외
      if (row < 0 || col < 0 || row >= state.guidBoard.length || col >= state.guidBoard.length) return;
      if (state.board[row][col] === -1) return;
      state.guidBoard[row][col] += status;
    },
    resetGuidBoard: function resetGuidBoard(state, action) {
      var width = action.payload.width;
      state.guidBoard = [];
      for (var i = 0; i < width; i++) {
        state.guidBoard.push(Array(width).fill(0));
      }
    },
    delRedGuidBoard: function delRedGuidBoard(state) {
      //가이드에 1로 표시된것들을 지운다.
      for (var i = 0; i < state.guidBoard.length; i++) {
        for (var j = 0; j < state.guidBoard.length; j++) {
          if (state.guidBoard[i][j] > 0) {
            state.guidBoard[i][j] &= ~1;
          }
        }
      }
    },
    //# 4: 강화 : 남은정령 강화
    reinforceTile: function reinforceTile(state, action) {
      var idx = action.payload.idx;
      if (state.cards[(idx + 1) % 2]["tier"] < 3) {
        state.cards[(idx + 1) % 2]["tier"] += 1;
      }
    },
    //# 5: 축복 : 횟수 소모 X = 횟수+1
    blessTile: function blessTile(state) {
      state.leftSummonCount += 1;
    },
    //# 8: 복제 : 쓴카드 복제하기. 반댓편으로 생성됨
    replicationTile: function replicationTile(state, action) {
      var idx = action.payload.idx;
      state.cards[(idx + 1) % 2]["name"] = state.cards[idx]["name"];
      state.cards[(idx + 1) % 2]["tier"] = state.cards[idx]["tier"];
    }
  }
});
var _choSlice$actions = choSlice.actions,
  move = _choSlice$actions.move,
  setBlessing = _choSlice$actions.setBlessing,
  setGear = _choSlice$actions.setGear,
  setStage = _choSlice$actions.setStage,
  summon = _choSlice$actions.summon,
  trade = _choSlice$actions.trade,
  addTrade = _choSlice$actions.addTrade,
  setBoard = _choSlice$actions.setBoard,
  setTile = _choSlice$actions.setTile,
  setNextCard = _choSlice$actions.setNextCard,
  setCard = _choSlice$actions.setCard,
  setCardTier = _choSlice$actions.setCardTier,
  setSelect = _choSlice$actions.setSelect,
  setGuidBoard = _choSlice$actions.setGuidBoard,
  resetGuidBoard = _choSlice$actions.resetGuidBoard,
  delRedGuidBoard = _choSlice$actions.delRedGuidBoard,
  reinforceTile = _choSlice$actions.reinforceTile,
  blessTile = _choSlice$actions.blessTile,
  replicationTile = _choSlice$actions.replicationTile,
  resetState = _choSlice$actions.resetState;
export { move, setBlessing, setGear, setStage, summon, trade, addTrade, setBoard, setTile, setNextCard, setCard, setCardTier, setSelect, setGuidBoard, resetGuidBoard, delRedGuidBoard, reinforceTile, blessTile, replicationTile, resetState };
export default choSlice.reducer;