import { createSlice } from '@reduxjs/toolkit';
var initialState = {
  isOpen: true,
  hanTextStr: "",
  timer: undefined,
  hanText: [[]],
  //한글을 입력한것
  ellaText: [[]] //엘라어키보드로 입력한것
};
var ellaSlice = createSlice({
  name: 'ella',
  initialState: initialState,
  reducers: {
    clear: function clear(state) {
      state.hanTextStr = "";
      state.hanText = [[]];
      state.ellaText = [[]];
      clearTimeout(state.timer);
    },
    open: function open(state) {
      state.isOpen = !state.isOpen;
    },
    hanTextInput: function hanTextInput(state, action) {
      var value = action.payload.value;
      state.hanTextStr = value;
    },
    hanInput: function hanInput(state, action) {
      var value = action.payload.value;
      state.hanText = value;
    },
    ellaInputAdd: function ellaInputAdd(state, action) {
      var value = action.payload.value;
      state.ellaText[state.ellaText.length - 1].push(value);
    },
    ellaInputNewline: function ellaInputNewline(state) {
      state.ellaText.push([]);
    },
    ellaInputDel: function ellaInputDel(state) {
      if (state.ellaText[state.ellaText.length - 1].pop() === undefined) {
        if (state.ellaText.length > 1) {
          //현재 줄 삭제
          state.ellaText.pop();
          //다음줄 한글자 삭제
          state.ellaText[state.ellaText.length - 1].pop();
        }
      }
    },
    ellaInputClear: function ellaInputClear(state) {
      state.ellaText = [[]];
    },
    setTimer: function setTimer(state, action) {
      var timer = action.payload.timer;
      state.timer = timer;
    }
  }
});
var _ellaSlice$actions = ellaSlice.actions,
  open = _ellaSlice$actions.open,
  clear = _ellaSlice$actions.clear,
  hanTextInput = _ellaSlice$actions.hanTextInput,
  hanInput = _ellaSlice$actions.hanInput,
  ellaInputAdd = _ellaSlice$actions.ellaInputAdd,
  ellaInputNewline = _ellaSlice$actions.ellaInputNewline,
  ellaInputDel = _ellaSlice$actions.ellaInputDel,
  ellaInputClear = _ellaSlice$actions.ellaInputClear,
  setTimer = _ellaSlice$actions.setTimer;
export { open, clear, hanTextInput, hanInput, ellaInputAdd, ellaInputNewline, ellaInputDel, ellaInputClear, setTimer };
export default ellaSlice.reducer;