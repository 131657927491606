export var attackArea = {
  업화: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/726ad44be20c49888f04399473ca6f1c.png",
  대폭발: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/c32a7350d29048878529d3f06dabe0f2.png",
  벼락: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/4bb26cc0d3a242fd91505ee4cea91c82.png",
  낙뢰: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/b037fe7c77ef4799a988db7e6e04ad46.png",
  용오름: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/d9e999226b6a4ceb8759526bb70960eb.png",
  충격파: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/71514bad8a4146f9b1a6f5b8114f12e9.png",
  지진: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/f4b98851cab74941b3435c9576c32302.png",
  해일: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/604c1ef2aad749a3b441c84e8279283f.png",
  폭풍우: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/bac5e115b4db441eb2442562e57b21fb.png",
  분출: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/37cbe5de006e41918d56069e38f9f7e5.png",
  정화: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/549b95f69aeb4b4fb4ed3f102ef1efc8.png",
  신목의정화: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/b037fe7c77ef4799a988db7e6e04ad46.png",
  세계수의공명: "https://cdn-lostark.game.onstove.com/uploadfiles/notice/412f0b0c33f0456588edacb1bc7e5a2d.png"
};
export var attackDelta = {
  업화: [[0, 0], [0, -1], [0, -2], [-1, 0], [-2, 0], [0, 1], [0, 2], [1, 0], [2, 0], [-1, -1], [-1, 1], [1, -1], [1, 1]],
  대폭발: [[0, 0], [1, 1], [2, 2], [3, 3], [4, 4], [5, 5], [6, 6], [7, 7], [-1, 1], [-2, 2], [-3, 3], [-4, 4], [-5, 5], [-6, 6], [-7, 7], [1, -1], [2, -2], [3, -3], [4, -4], [5, -5], [6, -6], [7, -7], [-1, -1], [-2, -2], [-3, -3], [-4, -4], [-5, -5], [-6, -6], [-7, -7]],
  벼락: [[0, 0]],
  낙뢰: [[0, 0], [-1, 0], [1, 0], [0, 1], [0, -1]],
  용오름: [[0, 0], [-1, -1], [1, 1], [-1, 1], [1, -1]],
  충격파: [[0, 0], [-1, 0], [1, 0], [0, 1], [0, -1], [-1, -1], [1, 1], [-1, 1], [1, -1]],
  지진: [[0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, -1], [0, -2], [0, -3], [0, -4], [0, -5], [0, -6], [0, -7]],
  해일: [[0, 0], [0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, -1], [0, -2], [0, -3], [0, -4], [0, -5], [0, -6], [0, -7], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [-1, 0], [-2, 0], [-3, 0], [-4, 0], [-5, 0], [-6, 0], [-7, 0]],
  폭풍우: [[0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [-1, 0], [-2, 0], [-3, 0], [-4, 0], [-5, 0], [-6, 0], [-7, 0]],
  분출: [[0, 0]],
  정화: [[0, 0], [0, -1], [0, 1]],
  신목의정화: [[0, 0], [-1, 0], [1, 0], [0, 1], [0, -1]],
  세계수의공명: [[0, 0], [-1, 0], [-2, 0], [1, 0], [2, 0], [0, 1], [0, 2], [0, -1], [0, -2]]
};

/**
 # -1: 없는자리
 # 0: 파괴된자리
 # 1: 파괴 가능한 자리
 # 2: 왜곡
 # 3: 추가 : 정령교체횟수 추가
 # 4: 강화 : 남은정령 강화
 # 5: 축복 : 횟수 소모 X
 # 6: 신비 : 세계수 or 분출로 남은카드 변경
 # 7: 재배치 : 재배치
 # 8: 복제 : 쓴카드 복제하기. 반댓편으로 생성됨
 */
export var boardMap = {
  투구: {
    1: [[-1, 1, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 1, -1]],
    2: [[-1, 1, 1, 1, 1, -1], [1, 2, 1, 1, 1, 1], [1, 1, 2, 1, 1, 1], [1, 1, 1, 2, 1, 1], [1, 1, 1, 1, 2, 1], [-1, 1, 1, 1, 1, -1]],
    3: [[-1, 1, 1, 1, 1, -1], [1, 2, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 2, 1], [-1, 1, 1, 1, 1, -1]],
    4: [[-1, -1, 1, 1, 1, -1, -1], [-1, 1, 2, 1, 1, 1, -1], [1, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 2, 1, -1], [-1, -1, 1, 1, 1, -1, -1]],
    5: [[-1, -1, 1, 1, 1, -1, -1], [-1, 1, 2, 1, 2, 1, -1], [1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1], [-1, 1, 2, 1, 2, 1, -1], [-1, -1, 1, 1, 1, -1, -1]],
    6: [[-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 2, 1, 1, 1, 1, -1], [1, 1, 1, 2, 1, 1, 2, 1], [1, 1, 1, 1, 1, 2, 1, 1], [1, 1, 2, 1, 1, 1, 1, 1], [1, 2, 1, 1, 2, 1, 1, 1], [-1, 1, 1, 1, 1, 2, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1]],
    7: [[-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 2, 2, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 2, 1], [1, 2, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 2, 2, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1]]
  },
  견갑: {
    1: [[1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1]],
    2: [[1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1], [1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1]],
    3: [[1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1]],
    4: [[1, 1, 1, 1, 1, 1, 1], [1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1], [1, 1, 1, 1, 1, 1, 1]],
    5: [[1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 2, 1, 1], [1, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 1], [1, 1, 2, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1]],
    6: [[1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 2, 1, 1], [1, 2, 1, 2, 1, 1, 1, 1], [1, 1, 1, 1, 1, 2, 1, 1], [1, 1, 2, 1, 1, 1, 1, 1], [1, 1, 1, 1, 2, 1, 2, 1], [1, 1, 2, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1]],
    7: [[1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 1, 2, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1, 1], [1, 1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 2, 1, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1]]
  },
  상의: {
    1: [[-1, -1, 1, 1, -1, -1], [-1, 1, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 1, -1], [-1, -1, 1, 1, -1, -1]],
    2: [[-1, -1, 1, 1, -1, -1], [-1, 1, 1, 1, 1, -1], [1, 1, 2, 2, 1, 1], [1, 1, 2, 2, 1, 1], [-1, 1, 1, 1, 1, -1], [-1, -1, 1, 1, -1, -1]],
    3: [[-1, -1, 1, 1, -1, -1], [-1, 1, 2, 1, 1, -1], [1, 1, 1, 1, 2, 1], [1, 2, 1, 1, 1, 1], [-1, 1, 1, 2, 1, -1], [-1, -1, 1, 1, -1, -1]],
    4: [[-1, -1, -1, 1, -1, -1, -1], [-1, -1, 1, 1, 2, -1, -1], [-1, 2, 2, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 2, 2, -1], [-1, -1, 2, 1, 1, -1, -1], [-1, -1, -1, 1, -1, -1, -1]],
    5: [[-1, -1, -1, 1, -1, -1, -1], [-1, -1, 2, 1, 1, -1, -1], [-1, 1, 1, 1, 1, 2, -1], [1, 1, 1, 2, 1, 1, 1], [-1, 2, 1, 1, 1, 1, -1], [-1, -1, 1, 1, 2, -1, -1], [-1, -1, -1, 1, -1, -1, -1]],
    6: [[-1, -1, -1, 1, 1, -1, -1, -1], [-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 2, 2, 1, 2, 1, -1], [1, 1, 1, 1, 1, 2, 1, 1], [1, 1, 2, 1, 1, 1, 1, 1], [-1, 1, 2, 1, 2, 2, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1], [-1, -1, -1, 1, 1, -1, -1, -1]],
    7: [[-1, -1, -1, 1, 1, -1, -1, -1], [-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 2, 1, 1, 2, 1, -1], [1, 2, 1, 1, 2, 1, 1, 1], [1, 1, 1, 2, 1, 1, 2, 1], [-1, 1, 2, 1, 1, 2, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1], [-1, -1, -1, 1, 1, -1, -1, -1]]
  },
  하의: {
    1: [[1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1]],
    2: [[1, 1, 1, 1, 1, 1], [1, 2, 2, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 2, 2, 1], [1, 1, 1, 1, 1, 1]],
    3: [[1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 1], [1, 1, 1, 2, 1, 1], [1, 1, 2, 1, 1, 1], [1, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1]],
    4: [[1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 1, 1, 1], [1, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 1], [1, 1, 1, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1]],
    5: [[1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1]],
    6: [[1, 1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 2, 1, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 1, 2, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1, 1]],
    7: [[1, 1, 1, 1, 1, 1, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 2, 1, 2, 2, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1, 1, 1], [1, 1, 2, 2, 1, 2, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 1, 1, 1, 1, 1, 1]]
  },
  장갑: {
    1: [[-1, 1, 1, 1, 1, -1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [-1, 1, 1, 1, 1, -1]],
    2: [[-1, 1, 1, 1, 1, -1], [1, 1, 1, 2, 1, 1], [1, 1, 1, 1, 2, 1], [1, 2, 1, 1, 1, 1], [1, 1, 2, 1, 1, 1], [-1, 1, 1, 1, 1, -1]],
    3: [[-1, 1, 1, 1, 1, -1], [1, 2, 1, 2, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 1, 1, 1, 1], [1, 1, 2, 1, 2, 1], [-1, 1, 1, 1, 1, -1]],
    4: [[-1, -1, 1, 1, 1, -1, -1], [-1, 1, 1, 1, 1, 1, -1], [1, 1, 2, 1, 2, 1, 1], [1, 1, 1, 2, 1, 1, 1], [1, 1, 2, 1, 2, 1, 1], [-1, 1, 1, 1, 1, 1, -1], [-1, -1, 1, 1, 1, -1, -1]],
    5: [[-1, -1, 1, 1, 1, -1, -1], [-1, 1, 1, 2, 1, 1, -1], [1, 1, 1, 1, 2, 1, 1], [1, 2, 1, 1, 1, 2, 1], [1, 1, 2, 1, 1, 1, 1], [-1, 1, 1, 2, 1, 1, -1], [-1, -1, 1, 1, 1, -1, -1]],
    6: [[-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 2, 1, 1, 2, 1, -1], [1, 2, 1, 1, 1, 1, 2, 1], [1, 1, 1, 2, 1, 1, 1, 1], [1, 1, 1, 1, 2, 1, 1, 1], [1, 2, 1, 1, 1, 1, 2, 1], [-1, 1, 2, 1, 1, 2, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1]],
    7: [[-1, -1, 1, 1, 1, 1, -1, -1], [-1, 1, 1, 2, 1, 1, 1, -1], [1, 1, 1, 1, 2, 1, 1, 1], [1, 1, 2, 1, 1, 1, 2, 1], [1, 2, 1, 1, 1, 2, 1, 1], [1, 1, 1, 2, 1, 1, 1, 1], [-1, 1, 1, 1, 2, 1, 1, -1], [-1, -1, 1, 1, 1, 1, -1, -1]]
  }
};
export var countData = {
  투구: {
    1: 7,
    2: 7,
    3: 7,
    4: 8,
    5: 8,
    6: 11,
    7: 11
  },
  견갑: {
    1: 7,
    2: 7,
    3: 7,
    4: 10,
    5: 10,
    6: 13,
    7: 13
  },
  상의: {
    1: 5,
    2: 5,
    3: 5,
    4: 5,
    5: 5,
    6: 8,
    7: 8
  },
  하의: {
    1: 7,
    2: 7,
    3: 7,
    4: 10,
    5: 10,
    6: 13,
    7: 13
  },
  장갑: {
    1: 7,
    2: 7,
    3: 7,
    4: 8,
    5: 8,
    6: 11,
    7: 11
  }
};