import { createSlice } from '@reduxjs/toolkit';
var initialState = {
  hp: {
    0: 14,
    12: 3,
    1: 3,
    11: 3,
    3: 3,
    9: 3,
    5: 3,
    7: 3,
    6: 3
  },
  time: {
    0: 100,
    12: 100,
    1: 100,
    11: 100,
    3: 100,
    9: 100,
    5: 100,
    7: 100,
    6: 100
  },
  timer: undefined,
  totalTime: 65,
  totalTimeFlag: false,
  yellowCount: 0,
  blueCount: 0
};
var harbrelSlice = createSlice({
  name: 'harbrel',
  initialState: initialState,
  reducers: {
    //action명:(state,action)=>{state.state명으로 접근, action.payload로 접근}
    reset: function reset(state) {
      state.hp = {
        0: 14,
        12: 3,
        1: 3,
        11: 3,
        3: 3,
        9: 3,
        5: 3,
        7: 3,
        6: 3
      };
      state.time = {
        0: 100,
        12: 100,
        1: 100,
        11: 100,
        3: 100,
        9: 100,
        5: 100,
        7: 100,
        6: 100
      };
      state.totalTime = 65;
      state.totalTimeFlag = false;
      state.yellowCount = 0;
      state.blueCount = 0;
      clearInterval(state.timer);
      state.timer = undefined;
    },
    setHp: function setHp(state, action) {
      var _action$payload = action.payload,
        index = _action$payload.index,
        value = _action$payload.value;
      state.hp[index] = value;
    },
    attack: function attack(state, action) {
      var _action$payload2 = action.payload,
        index = _action$payload2.index,
        value = _action$payload2.value;
      state.hp[index] = Math.max(state.hp[index] - value, 0);
    },
    //각 섹터 시간
    setTime: function setTime(state, action) {
      var _action$payload3 = action.payload,
        index = _action$payload3.index,
        value = _action$payload3.value;
      state.time[index] = value;
    },
    countTime: function countTime(state, action) {
      var index = action.payload.index;
      state.time[index] -= 1;
    },
    //전체 시간 리셋
    setTotaltime: function setTotaltime(state, action) {
      var plusTime = action.payload.plusTime;
      if (plusTime === 0) {
        state.totalTime = 60;
      } else {
        state.totalTime += plusTime;
      }
    },
    timeStart: function timeStart(state) {
      state.totalTimeFlag = !state.totalTimeFlag;
    },
    setTimer: function setTimer(state, action) {
      var timer = action.payload.timer;
      state.timer = timer;
    },
    //전체 노랑떨어진 갯수, 파랑 떨어진 갯수
    addYellowCount: function addYellowCount(state) {
      state.yellowCount += 1;
    },
    addBlueCount: function addBlueCount(state) {
      state.blueCount += 1;
    }
  }
});
var _harbrelSlice$actions = harbrelSlice.actions,
  reset = _harbrelSlice$actions.reset,
  setHp = _harbrelSlice$actions.setHp,
  attack = _harbrelSlice$actions.attack,
  setTime = _harbrelSlice$actions.setTime,
  countTime = _harbrelSlice$actions.countTime,
  setTotaltime = _harbrelSlice$actions.setTotaltime,
  timeStart = _harbrelSlice$actions.timeStart,
  setTimer = _harbrelSlice$actions.setTimer,
  addYellowCount = _harbrelSlice$actions.addYellowCount,
  addBlueCount = _harbrelSlice$actions.addBlueCount;
export { reset, setHp, attack, setTime, countTime, setTotaltime, timeStart, setTimer, addYellowCount, addBlueCount };
export default harbrelSlice.reducer;